.pure-img-responsive {
    max-width: 100%;
    height: auto;
}


/*
Add transition to containers so they can push in and out.
*/

@media screen {
    #layout,
    #menu,
    .menu-link {
        -webkit-transition: left 0.2s ease-out;
        -moz-transition: left 0.2s ease-out;
        -ms-transition: left 0.2s ease-out;
        -o-transition: left 0.2s ease-out;
        transition: left 0.2s ease-out;
    }
}


/*
This is the parent `<div>` that contains the menu and the content area.
*/

#layout {
    position: relative;
    padding-left: 0;
}

#layout.active #menu {
    left: 230px;
    width: 230px;
}

#layout.active .menu-link {
    left: 230px;
    .author {
        img {
            padding-left: 1em;
        }
    }
}

#side-menu {
    display: block;
}

#side-menu h3 {
    padding-left: 1.5em;
    color: #fff;
    margin-bottom: 0.2em;
}

#menu {
    margin-left: -230px;
    /* "#menu" width */
    width: 230px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    /* so the menu or its navicon stays above all content */
    background: #3382b7;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


/*
    All anchors inside the menu should be styled like this.
    */

#menu a {
    color: #FFF;
    border: none;
    padding: 0.5em 0 0.4em 1.6em;
}


/*
    Remove all background/borders, since we are applying them to #menu.
    */

#menu .pure-menu,
#menu .pure-menu ul {
    border: none;
    background: transparent;
}


/*
    Add that light border to separate items into groups.
    */

#menu .pure-menu ul,
#menu .pure-menu .menu-item-divided {
    border-top: 1px solid #333;
}


/*
        Change color of the anchor links on hover/focus.
        */

#menu .pure-menu li a:hover,
#menu .pure-menu li a:focus {
    background: #333;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}


/*
    This styles the selected menu item `<li>`.
    */

#menu .pure-menu-selected,
#menu .pure-menu-heading {
    background: #1f8dd6;
}


/*
        This styles a link within a selected menu item `<li>`.
        */

#menu .pure-menu-selected a {
    color: #fff;
}


/*
    This styles the menu heading.
    */

#menu .pure-menu-heading {
    font-size: 110%;
    color: #fff;
    margin: 0;
}


/* -- Dynamic Button For Responsive Menu -------------------------------------*/


/*
The button to open/close the Menu is custom-made and not part of Pure. Here's
how it works:
*/


/*
`.menu-link` represents the responsive menu toggle that shows/hides on
small screens.
*/

.menu-link {
    position: fixed;
    display: block;
    /* show this only on small screens */
    top: 0;
    left: 0;
    /* "#menu width" */
    background: #000;
    background: rgba(0, 0, 0, 0.7);
    font-size: 10px;
    /* change this value to increase/decrease button size */
    z-index: 10;
    width: 2em;
    height: auto;
    padding: 2.1em 1.6em;
}

.menu-link:hover,
.menu-link:focus {
    background: #000;
}

.menu-link span {
    position: relative;
    display: block;
}

.menu-link span,
.menu-link span:before,
.menu-link span:after {
    background-color: #fff;
    width: 100%;
    height: 0.2em;
}

.menu-link span:before,
.menu-link span:after {
    position: absolute;
    margin-top: -0.6em;
    content: " ";
}

.menu-link span:after {
    margin-top: 0.6em;
}


/* -- Responsive Styles (Media Queries) ------------------------------------- */


/*
Hides the menu at `48em`, but modify this based on your app's needs.
*/

@media (min-width: 48em) {
    .header,
    .content {
        padding-left: 2em;
        padding-right: 2em;
    }
    #layout {
        padding-left: 230px;
        /* left col width "#menu" */
        left: 0;
    }
    #menu {
        left: 230px;
    }
    .menu-link {
        position: fixed;
        left: 230px;
        display: none;
    }
    #layout.active .menu-link {
        left: 230px;
    }
}

@media (max-width: 48em) {
    /* Only apply this when the window is small. Otherwise, the following
    case results in extra padding on the left:
        * Make the window small.
        * Tap the menu to trigger the active state.
        * Make the window large again.
    */
    #menu {
        position: fixed;
    }
    #layout.active {
        position: relative;
        left: 230px;
        .author {
            img {
                padding-left: 1em;
            }
        }
    }
}


/*# sourceMappingURL=side-menu.css.map */


/*# sourceMappingURL=side-menu.css.map */